import * as React from 'react';
import Meta from 'styleguide/components/Meta/Meta';
import Footer from 'styleguide/components/Footer/Footer';
import FranchiseSolutionsHero from 'bundles/App/pages/service/FranchiseSolutions/FranchiseSolutionsHero/FranchiseSolutionsHero';
import Brands from 'bundles/App/pages/service/FranchiseSolutions/Brands/Brands';
import FranchiseProducts from 'bundles/App/pages/service/FranchiseSolutions/FranchiseProducts/FranchiseProducts';
import ButtonAsNativeLink from 'styleguide/components/Button/ButtonAsNativeLink';
import { franchisePermalinks } from 'bundles/App/pages/service/FranchiseSolutions/FranchiseProducts/types';
import { cards } from 'bundles/App/pages/service/FranchiseSolutions/Brands/types';

const FranchiseSolutions = props => (
  <>
    <Meta
      title="Franchise Solutions | Printivity"
      canonical="https://www.printivity.com/franchise-solutions"
      description="Franchise print and promo solutions that scale as you grow! Consistent branding and color, white-label portals, and a wide product selection."
      keywords="printivity, franchise, promotions, promo, branding, white-label"
    />
    <FranchiseSolutionsHero {...props} />
    <Brands
      cards={cards}
      brandHeading={"Build a brand that you're proud of."}
      brandTagline="Grow your franchisees with marketing and promotional procurement solutions."
    />
    <FranchiseProducts
      permalinks={franchisePermalinks}
      heading="A wide selection of print and promotional products ranging from Business Cards to Banners."
    />
    <div className="flex justify-center mt-10">
      <ButtonAsNativeLink
        buttonType="anchor"
        target="#contact-us"
        color="orange"
        className="max-w-md basis-[60%]"
      >
        Contact Us
      </ButtonAsNativeLink>
    </div>

    <Footer />
  </>
);

export default FranchiseSolutions;
