import * as React from 'react';
import cn from 'classnames';
import Grid from 'styleguide/components/Grid/Grid';
import FranchiseSolutionsForm from 'bundles/App/pages/service/FranchiseSolutions/FranchiseSolutionsForm/FranchiseSolutionsForm';
import { H1, H2 } from 'styleguide/components/Heading';
import IconCheckmark from 'styleguide/icons/IconCheckmark';

import heroImg from 'assets/images/business-card-stack.png';

interface Props {
  a?: string;
}

interface TextWithIconProps {
  children: string;
  icon: React.ReactNode;
  containerClass?: string;
  iconWrapperClass?: string;
  contentWrapperClass?: string;
}

const TextWithIcon = ({
  children,
  icon,
  containerClass,
  iconWrapperClass,
  contentWrapperClass,
}: TextWithIconProps) => (
  <div className={cn('relative my-3', containerClass)}>
    {!!icon && <div className={cn('absolute -left-5 sm:-left-8', iconWrapperClass)}>{icon}</div>}
    <div className={contentWrapperClass}>{children}</div>
  </div>
);

const checkIcon = () => <IconCheckmark color="white" size="sm" />;

const FranchiseSolutionsHero = (props: Props) => (
  <Grid.Container className="min-h-[500px] bg-blue">
    <Grid>
      <Grid.Row>
        <Grid.Col lg={6} className="!text-white">
          <H1 className="!text-white !text-lg mt-5 mb-0 !mx-0 leading-6 !font-hvLight lg:mt-24 !tracking-normal">
            FRANCHISE SOLUTIONS
          </H1>
          <H2 className="!text-white !text-5xl sm:!text-7xl leading-none my-5 !mx-0 !tracking-normal">
            Deliver a true brand experience.
          </H2>
          <H2 className="!text-white !text-xl sm:!text-3xl leading-none !mx-0 !tracking-normal">
            Empower franchises to market themselves with print and promo.
          </H2>
          <div className="text-white text-base leading-none ml-5 sm:ml-8 mt-5">
            <TextWithIcon icon={checkIcon()}>Consistent brand colors on all print and promo.</TextWithIcon>
            <TextWithIcon icon={checkIcon()}>Unified spend for better pricing.</TextWithIcon>
            <TextWithIcon icon={checkIcon()}>Brand portals for single point-of-ordering.</TextWithIcon>
            <TextWithIcon icon={checkIcon()}>Rush turnarounds and nationwide shipping.</TextWithIcon>
          </div>
        </Grid.Col>
        <Grid.Col
          className="bg-cover bg-no-repeat min-h-[425px] bg-center lg:min-h-auto"
          style={{ backgroundImage: `url(${heroImg})` }}
          lg={6}
        >
          <div className="flex justify-center m-5">
            <FranchiseSolutionsForm className="flex justify-center m-5" {...props} />
          </div>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  </Grid.Container>
);

export default FranchiseSolutionsHero;
